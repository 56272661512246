import React, { useEffect, useState } from "react";
import {
  DownloadOutlined,
  SyncOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { CModal, CSpinner } from "@coreui/react";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import { useSelector } from "react-redux";
import {
  BUTTON_COLORS,
  QuestButton,
} from "../../../../../common/components/QuestButton";
import { ENTITY_TYPES } from "../../../../../common/constants/entityTypes";
import { LOADING_STATUS } from "../../../../../common/constants/loadingStatuses";
import { dateFormat } from "../../../../../common/utils/date";
import { getExtras } from "../../../../actions/creators/extras";
import { APPLICATION_EXTRA_KEYS } from "../../../../constants/applicationAdmin";
import {
  GUARANTOR1_EQUIFAX_APPLY,
  GUARANTOR1_DOCUMENT,
  GUARANTOR2_EQUIFAX_APPLY,
  GUARANTOR2_DOCUMENT,
  QUEST_FINANCE_PRIVACY_FORM,
} from "../../../../constants/extra";
import { TRUSTEE_TYPES } from "../../../../constants/trusteeTypes";
import { useExtraDispatch } from "../../../../dispatchers";
import {
  useApplicantEquifax,
  useGuarantorEquifax,
} from "../../../../hooks/creditReport";
import {
  getApplicationSelector,
  getIsApplicationStatusLockedSelector,
} from "../../../../selectors/applicationForm";
import "./CreditReport.scss";
import {
  extraStatusSelector,
  extrasDataSelector,
} from "../../../../selectors/extras";
import { PrivacyFormExtras } from "../../../../types/Extras";
import PrivacyForm from "../PrivacyForm/PrivacyForm";

type RerunState = {
  date: string;
  func: null | (() => Promise<void>);
};

const dateDisplayFormat = "MMM dd, yyyy hh:mm a";

type RerunConfirmationProps = {
  onCancel: () => void;
  onConfirm: () => void;
  data: RerunState | undefined;
};

const RerunConfirmation: React.FC<RerunConfirmationProps> = (
  props: RerunConfirmationProps
) => {
  const { onCancel, onConfirm, data } = props;

  return (
    <CModal
      className="confirm-modal"
      show={Boolean(data?.date)}
      onClose={onCancel}
      closeOnBackdrop={false}
      data-testid="confirm-rerun-modal"
    >
      <div className="m-3 mx-5">
        <h3>
          <WarningOutlined className="warn-icon" />
          Run credit report again
        </h3>
        <div className="mb-3">
          You have previously run a credit report on{" "}
          <div data-testid="date-last-run">
            {data &&
              dateFormat(new Date(data?.date as string), dateDisplayFormat)}
          </div>
        </div>
        <div>
          This action will create another credit enquiry on the customers file
          and incur additional cost.
        </div>
      </div>
      <div className="m-3 text-center">
        <QuestButton
          color={BUTTON_COLORS.SECONDARY}
          onClick={onCancel}
          className="mr-3"
          type="button"
          data-testid="cancel-rerun-btn"
        >
          Cancel
        </QuestButton>
        {Boolean(data?.date) && (
          <QuestButton
            color={BUTTON_COLORS.COMMIT}
            onClick={onConfirm}
            className="mr-3"
            type="button"
            data-testid="confirm-rerun-btn"
          >
            New Search
          </QuestButton>
        )}
      </div>
    </CModal>
  );
};

const CreditScore: React.FC = () => {
  const dispatch = useExtraDispatch();
  const extrasStatus = useSelector(extraStatusSelector);
  const extrasIsLoading = extrasStatus === LOADING_STATUS.LOADING;
  const [rerunConfirmation, setRerunConfirmation] = useState<
    RerunState | undefined
  >();
  const application = useSelector(getApplicationSelector);
  const extrasData = useSelector(extrasDataSelector);
  const privacyFormExtra = _get(
    extrasData,
    QUEST_FINANCE_PRIVACY_FORM
  ) as PrivacyFormExtras;
  const applicant = application.applicant;
  const applicantEquifax = useApplicantEquifax(
    application.id,
    applicant?.entityId
  );
  const isApplicationLocked = useSelector(getIsApplicationStatusLockedSelector);
  const guarantor1 = useGuarantorEquifax(
    application.guarantors[0],
    application.id,
    GUARANTOR1_EQUIFAX_APPLY,
    GUARANTOR1_DOCUMENT
  );
  const guarantor2 = useGuarantorEquifax(
    application.guarantors[1],
    application.id,
    GUARANTOR2_EQUIFAX_APPLY,
    GUARANTOR2_DOCUMENT
  );
  let applicantRow = null;
  let guarantor1Row = null;
  let guarantor2Row = null;

  useEffect(() => {
    if (application.id) {
      dispatch(getExtras(application.id, APPLICATION_EXTRA_KEYS));
    }
  }, [dispatch, application]);

  const confirmRerun = (stateVal: RerunState) => {
    setRerunConfirmation(stateVal);
  };

  const onCancelRerun = () => {
    setRerunConfirmation(undefined);
  };

  const onConfirmRerun = () => {
    setRerunConfirmation(undefined);
    rerunConfirmation?.func?.();
  };

  if (
    applicant?.entityId &&
    (applicant.entityType === ENTITY_TYPES.COMPANY ||
      applicant.trusteeType === TRUSTEE_TYPES.COMPANY)
  ) {
    const {
      equifaxData,
      document,
      isLoadingEquifax,
      loadEquifaxRequest,
      downloadDocument,
    } = applicantEquifax;

    let button = (
      <button
        className="quest-button primary"
        onClick={loadEquifaxRequest}
        disabled={extrasIsLoading || isApplicationLocked}
        data-testid="applicant-equifax-btn"
      >
        {isLoadingEquifax && (
          <CSpinner
            size="sm"
            className="my-1 mx-2"
            data-testid="applicant-run-icon"
          />
        )}
        Credit Search
      </button>
    );

    if (!_isEmpty(document)) {
      button = (
        <>
          <button
            className="quest-button primary"
            onClick={downloadDocument}
            disabled={extrasIsLoading}
            data-testid="applicant-equifax-doc-btn"
          >
            Credit Report
            <DownloadOutlined className="download-icon" />
          </button>
          {Boolean(document?.updatedAt) && (
            <div className="date" data-testid="applicant-document-date">
              {dateFormat(
                new Date(document?.updatedAt as string),
                dateDisplayFormat
              )}
            </div>
          )}
        </>
      );
    }

    const rerunButton = (
      <button
        className="rerun"
        disabled={
          !equifaxData?.updatedAt || extrasIsLoading || isApplicationLocked
        }
        onClick={() =>
          confirmRerun({
            date: equifaxData?.updatedAt as string,
            func: loadEquifaxRequest,
          })
        }
        data-testid="appplicant-rerun"
      >
        <SyncOutlined
          title="Rerun"
          spin={Boolean(equifaxData?.updatedAt) && isLoadingEquifax}
          data-testid="applicant-rerun-spin"
        />
      </button>
    );

    applicantRow = (
      <div className="cs-item">
        <div className="name" data-testid="applicant-entity-name">
          {applicant.entityName}
        </div>
        <div className="col2">
          <PrivacyForm
            applicationId={application.id}
            data={privacyFormExtra}
            disabled={extrasIsLoading || isApplicationLocked}
          />
        </div>
        <div className="col3">{button}</div>
        <div className="col4" data-testid="applicant-score">
          {equifaxData?.score}
        </div>
        <div className="col5">{rerunButton}</div>
      </div>
    );
  }

  if (guarantor1.entityId) {
    const {
      name,
      equifaxData,
      document,
      isLoadingEquifax,
      loadEquifaxRequest,
      downloadDocument,
    } = guarantor1;

    let button = (
      <button
        className="quest-button primary"
        onClick={loadEquifaxRequest}
        disabled={extrasIsLoading || isApplicationLocked}
        data-testid="guarantor1-equifax-btn"
      >
        {isLoadingEquifax && (
          <CSpinner
            size="sm"
            className="my-1 mx-2"
            data-testid="guarantor1-run-icon"
          />
        )}
        Credit Search
      </button>
    );
    if (!_isEmpty(document)) {
      button = (
        <>
          <button
            className="quest-button primary"
            onClick={downloadDocument}
            disabled={extrasIsLoading}
            data-testid="guarantor1-equifax-doc-btn"
          >
            Credit Report
            <DownloadOutlined className="download-icon" />
          </button>
          {Boolean(document?.updatedAt) && (
            <div className="date" data-testid="guarantor1-document-date">
              {dateFormat(
                new Date(document?.updatedAt as string),
                dateDisplayFormat
              )}
            </div>
          )}
        </>
      );
    }

    const rerunButton = (
      <button
        className="rerun"
        data-testid="guarantor1-rerun"
        disabled={
          !equifaxData?.updatedAt || extrasIsLoading || isApplicationLocked
        }
        onClick={() =>
          confirmRerun({
            date: equifaxData?.updatedAt as string,
            func: loadEquifaxRequest,
          })
        }
      >
        <SyncOutlined
          title="Rerun"
          spin={Boolean(equifaxData?.updatedAt) && isLoadingEquifax}
          data-testid="guarantor1-rerun-spin"
        />
      </button>
    );

    guarantor1Row = (
      <div className="cs-item">
        <div data-testid="guarantor1-name">{name}</div>
        <div className="col2" />
        <div className="col3">{button}</div>
        <div className="col4" data-testid="guarantor1-score">
          {equifaxData?.score}
        </div>
        <div className="col5">{rerunButton}</div>
      </div>
    );
  }

  if (guarantor2.entityId) {
    const {
      name,
      equifaxData,
      document,
      isLoadingEquifax,
      loadEquifaxRequest,
      downloadDocument,
    } = guarantor2;

    let button = (
      <button
        className="quest-button primary"
        onClick={loadEquifaxRequest}
        disabled={extrasIsLoading || isApplicationLocked}
        data-testid="guarantor2-equifax-btn"
      >
        {isLoadingEquifax && (
          <CSpinner
            size="sm"
            className="my-1 mx-2"
            data-testid="guarantor2-run-icon"
          />
        )}
        Credit Search
      </button>
    );

    if (!_isEmpty(document)) {
      button = (
        <>
          <div>
            <button
              className="quest-button primary"
              onClick={downloadDocument}
              disabled={extrasIsLoading}
              data-testid="guarantor2-equifax-doc-btn"
            >
              Credit Report
              <DownloadOutlined className="download-icon" />
            </button>
          </div>
          {Boolean(document?.updatedAt) && (
            <span className="date" data-testid="guarantor2-document-date">
              {dateFormat(
                new Date(document?.updatedAt as string),
                dateDisplayFormat
              )}
            </span>
          )}
        </>
      );
    }

    const rerunButton = (
      <button
        className="rerun"
        disabled={
          !equifaxData?.updatedAt || extrasIsLoading || isApplicationLocked
        }
        onClick={() =>
          confirmRerun({
            date: equifaxData?.updatedAt as string,
            func: loadEquifaxRequest,
          })
        }
        data-testid="guarantor2-rerun"
      >
        <SyncOutlined
          title="Rerun"
          spin={Boolean(equifaxData?.updatedAt) && isLoadingEquifax}
          data-testid="guarantor2-rerun-spin"
        />
      </button>
    );

    guarantor2Row = (
      <div className="cs-item">
        <div data-testid="guarantor-2-name">{name}</div>
        <div className="col2" />
        <div className="col3">{button}</div>
        <div className="col4" data-testid="guarantor2-score">
          {equifaxData?.score}
        </div>
        <div className="col5">{rerunButton}</div>
      </div>
    );
  }

  return (
    <>
      <RerunConfirmation
        data={rerunConfirmation}
        onCancel={onCancelRerun}
        onConfirm={onConfirmRerun}
      />
      <div className="credit-report">
        {applicantRow}
        {guarantor1Row}
        {guarantor2Row}
      </div>
    </>
  );
};

export default CreditScore;
