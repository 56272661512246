import { APPLICATION_STATUSES } from "./applicationStatuses";
import {
  APPLICANT_ENQUIRY_DOCUMENT,
  APPLICANT_EQUIFAX_ENQUIRY_KEY,
  GUARANTOR1_DOCUMENT,
  GUARANTOR1_EQUIFAX_APPLY,
  GUARANTOR2_DOCUMENT,
  GUARANTOR2_EQUIFAX_APPLY,
  QUEST_FINANCE_PRIVACY_FORM,
} from "./extra";

export const statusLenderFormDefaults = {
  status: APPLICATION_STATUSES.QUOTED,
  lenderName: "",
  lenderRef: "",
};

export const APPLICATION_EXTRA_KEYS = {
  [APPLICANT_EQUIFAX_ENQUIRY_KEY]: ["score"],
  [APPLICANT_ENQUIRY_DOCUMENT]: ["id"],
  [GUARANTOR1_EQUIFAX_APPLY]: ["score"],
  [GUARANTOR1_DOCUMENT]: ["id"],
  [GUARANTOR2_EQUIFAX_APPLY]: ["score"],
  [GUARANTOR2_DOCUMENT]: ["id"],
  [QUEST_FINANCE_PRIVACY_FORM]: ["dateSent", "dateSigned"],
};

export const lenders = [
  "AAMF",
  "AFS",
  "Alex Bank",
  "Angle Finance",
  "Azora",
  "Banjo",
  "BigStone",
  "BOQ Finance",
  "Branded Financial Services",
  "Capify",
  "Capital Finance",
  "CBA",
  "Finance One",
  "FirstMac",
  "Flex Fleet",
  "Flexicommercial",
  "Green Light",
  "Grenke",
  "Grow Finance",
  "Iron Capital",
  "Liberty",
  "LUMI Finance",
  "Macquarie Leasing",
  "Macquarie Rentals",
  "Maia NZ",
  "Metro Finance",
  "Moneytech",
  "Morris Finance",
  "Moula",
  "Multipli",
  "NAB",
  "NOW Finance",
  "OnDeck",
  "Orix",
  "Pepper",
  "Plenti",
  "Prospa",
  "Quest Finance",
  "Quest Finance - Bill & Collect",
  "Resimac",
  "Scotpac",
  "Selfco",
  "Shift",
  "Thorn Money",
  "Vado Private Commercial Real Estate",
  "Vestone",
  "VW Finance",
  "Westlawn",
  "Westpac",
  "Other",
];
