import React, { useCallback } from "react";
import {
  CRow,
  CCol,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CNavItem,
  CNavLink,
  CNav,
  CDropdown,
} from "@coreui/react";
import { AxiosError } from "axios";
import cs from "classnames";
import { ReactSVG } from "react-svg";
import { useDebouncedCallback } from "use-debounce";
import ArrowWhiteRight from "../../../common/assets/images/arrow-right-white.svg";
import { processErrorMessage } from "../../../error-handler/utils";
import ClientDropdown, {
  TClient,
} from "../../../iam/components/ClientDropdown";
import UserDropdown, {
  TUser,
  TUserQuery,
} from "../../../iam/components/UserDropdown";
import ClientService from "../../../iam/services/ClientService";
import { setNotification } from "../../../notification/actions/creators";
import { useNotificationDispatch } from "../../../notification/dispatchers";
import UserService from "../../../user/services/UserService";
import { APPLICATION_PUBLIC_STATUS_LABELS } from "../../constants/applicationStatuses";

export type FilterData = {
  status: string;
  user: TUser | null;
  client: TClient | null;
};

const defaultFilterData: FilterData = {
  status: "",
  user: null,
  client: null,
};

export type ApplicationFilterProps = {
  onFilterChange?: (values: FilterData) => void;
  filterData?: FilterData;
  showReferrerFilter?: boolean;
} & React.HTMLProps<HTMLDivElement>;

const ApplicationFilter: React.FC<ApplicationFilterProps> = (
  props: ApplicationFilterProps
) => {
  const showReferrerFilter = props?.showReferrerFilter ?? false;
  const filterData = {
    ...defaultFilterData,
    ...props.filterData,
  };
  const notifDispatch = useNotificationDispatch();
  const onFilterChange = (dataProp: Partial<FilterData>) => {
    const filter = {
      ...filterData,
      ...dataProp,
    };

    if ("client" in dataProp && dataProp.client !== filterData.client) {
      filter.user = null;
    }

    props.onFilterChange?.(filter);
  };

  const getClientOptions = useCallback(
    async (keyword, cb) => {
      try {
        const clients = await ClientService.listManagedClients({ keyword });

        cb(
          clients.data.map((client) => {
            return {
              label: client.name,
              value: client.id,
            };
          })
        );
      } catch (error) {
        const message = processErrorMessage(error as AxiosError);
        notifDispatch(
          setNotification({
            body: message,
            className: "qst-notif-danger",
          })
        );
      }
    },
    [notifDispatch]
  );

  const debouncedGetClientOptions = useDebouncedCallback((input, cb) => {
    getClientOptions(input, cb);
  }, 700);

  const getUserOptions: TUserQuery = useCallback(
    async (keyword, cb) => {
      try {
        const users = await UserService.listManagedUsers({
          clientId: filterData.client?.value,
          keyword,
        });

        cb(
          users.data.map((user) => {
            return {
              label: `${user.firstName} ${user.lastName}`,
              value: user.id,
            };
          })
        );
      } catch (error) {
        const message = processErrorMessage(error as AxiosError);
        notifDispatch(
          setNotification({
            body: message,
            className: "qst-notif-danger",
          })
        );
      }
    },
    [notifDispatch, filterData.client?.value]
  );

  const debouncedGetUserOptions = useDebouncedCallback((input, cb) => {
    getUserOptions(input, cb);
  }, 700);

  const btnFilterMore = Object.keys(APPLICATION_PUBLIC_STATUS_LABELS).slice(
    Math.max(Object.keys(APPLICATION_PUBLIC_STATUS_LABELS).length - 2, 0)
  );

  return (
    <>
      <CRow>
        <CCol xs="12">
          <h4 className="f-bold f-quest-navy">Application filters:</h4>
        </CCol>
        {showReferrerFilter && (
          <>
            <CCol md="12" lg="4" className="mt-2">
              <ClientDropdown
                name="client"
                value={filterData.client}
                loadOptions={debouncedGetClientOptions}
                onChange={(client) => {
                  onFilterChange({ client });
                }}
              />
            </CCol>
            <CCol md="12" lg="4" className="mt-2">
              <UserDropdown
                name="user"
                key={filterData.client?.value}
                value={filterData.user}
                loadOptions={debouncedGetUserOptions}
                onChange={(user) => {
                  onFilterChange({ user });
                }}
              />
            </CCol>
          </>
        )}
      </CRow>
      <CRow className="mt-3">
        <CCol xs="12">
          <CNav variant="pills" className="quest-nav-pills mb-2">
            <CNavItem className="mr-3">
              <CNavLink
                className={cs("quest-nav-link", {
                  active: !filterData.status,
                })}
                onClick={() =>
                  onFilterChange({
                    status: undefined,
                  })
                }
                data-testid={`recent-apps`}
              >
                Recent Apps
              </CNavLink>
            </CNavItem>
            {Object.keys(APPLICATION_PUBLIC_STATUS_LABELS)
              .slice(1, -2)
              .map((item, index) => {
                return (
                  <CNavItem key={index} className="mr-3">
                    <CNavLink
                      className={cs("quest-nav-link", {
                        active: filterData.status === item,
                      })}
                      onClick={() => {
                        onFilterChange({
                          status: item,
                        });
                      }}
                      data-testid={`status-${item}`}
                    >
                      {APPLICATION_PUBLIC_STATUS_LABELS[item]}
                    </CNavLink>
                  </CNavItem>
                );
              })}
            <CNavItem className="mr-3">
              <CDropdown className="quest-dropdown">
                <CDropdownToggle
                  className={cs("quest-nav-link", {
                    active: btnFilterMore.includes(filterData.status as string),
                  })}
                  data-testid="more-button"
                >
                  More <ReactSVG className="chevron" src={ArrowWhiteRight} />
                  <CDropdownMenu
                    className="quest-dropdown-menu"
                    placement="bottom-end"
                  >
                    {btnFilterMore.map((item, index) => {
                      return (
                        <CDropdownItem
                          onClick={() => {
                            onFilterChange({
                              status: item,
                            });
                          }}
                          key={index}
                          className={cs("quest-dropdown-item", {
                            active: filterData.status === item,
                          })}
                          data-testid={`status-${item}`}
                        >
                          {APPLICATION_PUBLIC_STATUS_LABELS[item]}
                        </CDropdownItem>
                      );
                    })}
                  </CDropdownMenu>
                </CDropdownToggle>
              </CDropdown>
            </CNavItem>
          </CNav>
        </CCol>
      </CRow>
    </>
  );
};

export default ApplicationFilter;
